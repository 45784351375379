import { useQueryClient } from '@tanstack/react-query';
import PresentationAPIGen, {
  SymbolModel
} from 'ecto-common/lib/API/PresentationAPIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useContext } from 'react';

const emptySymbols: SymbolModel[] = [];

export const useProcessMapLibrary = () => {
  const libraryRequest = PresentationAPIGen.Symbols.listSymbols.useQuery(
    {
      $top: 1000, // "Disable" pagination for now until we add proper UI support for it.,
      $orderby: 'name asc'
    },
    {
      staleTime: Infinity
    }
  );

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const invalidateLibraryRequests = (itemId?: string) => {
    if (itemId != null) {
      queryClient.invalidateQueries({
        queryKey: PresentationAPIGen.Symbols.getSymbol.path(contextSettings, {
          id: itemId
        })
      });
    }

    queryClient.invalidateQueries({
      queryKey: PresentationAPIGen.Symbols.listSymbols.path(contextSettings)
    });
  };

  return {
    libraryRequest,
    libraryItems: libraryRequest.data?.symbols ?? emptySymbols,
    invalidateLibraryRequests
  };
};
