import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ProcessMapTable from 'ecto-common/lib/ProcessMaps/ProcessMapTable';
import T from 'ecto-common/lib/lang/Language';
import {
  calculateDataTableMinHeight,
  standardColumns
} from 'ecto-common/lib/utils/dataTableUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import _ from 'lodash';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import styles from './SelectProcessMapDialog.module.css';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import APIGen, {
  EquipmentTypeProcessMapRelationshipResponseModel,
  NodeProcessMapRelationshipResponseModel,
  ProcessMapResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const topColumns = [
  {
    label: T.admin.processmaps.name,
    dataKey: 'name',
    idKey: 'id',
    linkColumn: true,
    dataFormatter: (value: string) => (
      <>
        <Icons.File /> {value}{' '}
      </>
    )
  }
];

/**
 * Theoretically we could fetch only the process map ID in this promise and just
 * get the process map from our complete collection. However, I think we will need
 * to paginate the process map list in the future so I anticipate that and get the
 * specific process map in a separate call instead.
 */
const getRelationPromise = (
  contextSettings: ApiContextSettings,
  nodeId: string,
  equipmentTypeId: string,
  signal: AbortSignal
) => {
  return Promise.all([
    nodeId
      ? APIGen.AdminProcessMaps.getProcessMapRelationshipsByNodeId.promise(
          contextSettings,
          { nodeIds: [nodeId] },
          signal
        )
      : Promise.resolve<NodeProcessMapRelationshipResponseModel[]>([]),
    equipmentTypeId
      ? APIGen.AdminProcessMaps.getProcessMapRelationshipsByEquipmentTypeId.promise(
          contextSettings,
          {
            equipmentTypeIds: [equipmentTypeId]
          },
          signal
        )
      : Promise.resolve<EquipmentTypeProcessMapRelationshipResponseModel[]>([])
  ] as const).then((relations) => {
    const nodeRelations = relations[0];
    const relation = _.head(nodeRelations);
    const equipmentRelations: EquipmentTypeProcessMapRelationshipResponseModel[] =
      relations[1];
    const equipmentTypeRelation = _.head(equipmentRelations);

    return Promise.all([
      relation?.processMapId
        ? APIGen.AdminProcessMaps.getProcessMapsById.promise(
            contextSettings,
            { ids: [relation.processMapId] },
            signal
          )
        : Promise.resolve<ProcessMapResponseModel[]>([]),
      equipmentTypeRelation?.processMapId
        ? APIGen.AdminProcessMaps.getProcessMapsById.promise(
            contextSettings,
            { ids: [equipmentTypeRelation.processMapId] },
            signal
          )
        : Promise.resolve<ProcessMapResponseModel[]>([])
    ]);
  });
};

const updateRelationPromise = ({
  contextSettings,
  nodeId,
  equipmentTypeId,
  processMapId
}: {
  contextSettings: ApiContextSettings;
  nodeId: string;
  equipmentTypeId: string;
  processMapId: string;
}): Promise<unknown> => {
  if (processMapId == null) {
    return nodeId != null
      ? APIGen.AdminProcessMaps.deleteNodeProcessMapRelationships.promise(
          contextSettings,
          { nodeIds: [nodeId] },
          null
        )
      : APIGen.AdminProcessMaps.deleteEquipmentTypeProcessMapRelationships.promise(
          contextSettings,
          { equipmentTypeIds: [equipmentTypeId] },
          null
        );
  }

  return nodeId != null
    ? APIGen.AdminProcessMaps.addOrUpdateNodeProcessMapRelationships.promise(
        contextSettings,
        [{ nodeId, processMapId }],
        null
      )
    : APIGen.AdminProcessMaps.addOrUpdateEquipmentTypeProcessMapRelationships.promise(
        contextSettings,
        [{ equipmentTypeId, processMapId }],
        null
      );
};

interface SelectProcessMapDialogProps {
  isOpen?: boolean;
  onModalClose: () => void;
  nodeId?: string;
  equipmentTypeId?: string;
  onConfirm?: (processMap: ProcessMapResponseModel) => void;
  actionText?: React.ReactNode;
}

const SelectProcessMapDialog = ({
  isOpen,
  onModalClose,
  nodeId,
  equipmentTypeId,
  onConfirm = null,
  actionText = T.common.save
}: SelectProcessMapDialogProps) => {
  const [searchString, setSearchString] = useState('');
  const [selectedData, setSelectedData] = useState<ProcessMapResponseModel[]>(
    []
  );
  const [hasChanges, setHasChanges] = useState(false);

  const onClickRow = useCallback((object: ProcessMapResponseModel) => {
    setHasChanges(true);
    setSelectedData((oldSelectedData) => {
      if (_.head(oldSelectedData)?.id === object.id) {
        return [];
      }

      return [object];
    });
  }, []);

  const { contextSettings } = useContext(TenantContext);

  const relationsQuery = useQuery({
    queryKey: _.compact(['processMapDialogRelations', nodeId, equipmentTypeId]),

    queryFn: ({ signal }) => {
      return getRelationPromise(
        contextSettings,
        nodeId,
        equipmentTypeId,
        signal
      );
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData
  });

  const equipmentDefaultMap =
    nodeId == null ? null : relationsQuery.data?.[1]?.[0];

  const queryClient = useQueryClient();
  const saveRelationMutation = useMutation({
    mutationFn: updateRelationPromise,

    onSuccess: () => {
      onModalClose();
      queryClient.invalidateQueries({
        queryKey: ['processMapDialogRelations']
      });
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.error.update);
    }
  });

  const processMapsQuery = APIGen.AdminProcessMaps.getAllProcessMaps.useQuery();

  const items = useMemo(() => {
    if (processMapsQuery.data != null) {
      return _.keyBy(processMapsQuery.data, 'id');
    }

    return {};
  }, [processMapsQuery.data]);

  useEffect(() => {
    if (isOpen && relationsQuery.data) {
      const processMaps = relationsQuery.data;
      if (nodeId == null) {
        // when we don't have a node id, we are in select process map for templates
        // So we switch the result here
        setSelectedData(processMaps[1]);
      } else {
        setSelectedData(processMaps[0] ?? []);
      }
    } else {
      setSelectedData([]);
      setHasChanges(false);
    }
  }, [isOpen, nodeId, equipmentTypeId, relationsQuery.data]);

  const onDelete = useCallback(() => {
    setSelectedData([]);
    setHasChanges(true);
  }, []);

  const allTopColumns = useMemo(() => {
    return [...topColumns, ...standardColumns({ onDelete })];
  }, [onDelete]);

  const onConfirmClick = useCallback(() => {
    if (onConfirm) {
      onConfirm(_.head(selectedData));
    } else {
      saveRelationMutation.mutate({
        contextSettings,
        nodeId,
        equipmentTypeId,
        processMapId: _.head(selectedData)?.id
      });
    }
  }, [
    onConfirm,
    selectedData,
    saveRelationMutation,
    contextSettings,
    nodeId,
    equipmentTypeId
  ]);

  const onChangeSearchString = useCallback(
    (changeEvent: React.ChangeEvent<HTMLInputElement>) =>
      setSearchString(changeEvent.target.value),
    []
  );

  const noDataText = useMemo(() => {
    if (
      nodeId == null ||
      equipmentTypeId == null ||
      equipmentDefaultMap == null
    ) {
      return T.admin.processmaps.nocurrentmap;
    }
    return _.join(
      T.format(
        T.admin.processmaps.nocurrentmapdefault,
        equipmentDefaultMap.name ?? ''
      ),
      ''
    );
  }, [equipmentDefaultMap, equipmentTypeId, nodeId]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      title={T.admin.editlocation.editprocessmap}
      onConfirmClick={onConfirmClick}
      actionText={actionText}
      disableActionButton={!hasChanges}
      headerIcon={Icons.Settings}
      isLoading={saveRelationMutation.isPending || processMapsQuery.isLoading}
      className={styles.dialog}
    >
      <KeyValueLine>
        <KeyValueGeneric keyText={T.admin.processmaps.currentmap}>
          <DataTable
            isLoading={relationsQuery.isLoading}
            columns={allTopColumns}
            data={selectedData}
            noDataText={noDataText}
            minHeight={calculateDataTableMinHeight({
              pageSize: 1,
              withButtons: true,
              showNoticeHeaders: false
            })}
            showNoticeHeaders={false}
            hasError={
              relationsQuery.error != null || processMapsQuery.error != null
            }
          />
        </KeyValueGeneric>
      </KeyValueLine>

      <TextInput
        placeholder={T.common.search.placeholder}
        value={searchString}
        icon={<Icons.Search />}
        onChange={onChangeSearchString}
      />

      <ProcessMapTable
        searchString={searchString}
        onClickRow={onClickRow}
        showCheckboxes
        selectedId={_.head(selectedData)?.id}
        isLoading={relationsQuery.isLoading || processMapsQuery.isLoading}
        items={items}
        defaultItemId={equipmentDefaultMap?.id}
      />
    </ActionModal>
  );
};

export default SelectProcessMapDialog;
